import imgRecognizingNarcolepsy from '../../../assets/images/clinician-guide-recognizing-narcolepsy.png'
import imgDiagnosingNarcolepsy from '../../../assets/images/clinician-guide-sleep-specialist-diagnosing-narcolepsy.png'
import imgNarcolepsySymptom from '../../../assets/images/narcolepsy-symptom-screener-tearpad.png'
import imgPatientNarcolepsy from '../../../assets/images/patient-guide-understanding-narcolespy.jpg'
import imgResourceKit from '../../../assets/images/clinical-resource-kit-recognizing-narcolepsy.png'

import imgClinicianGuideNew from '../../../assets/images/clinician-guide-narcolepsy-in-pediatric-patients.jpg';
import imgCaregiverPedGuideNew from '../../../assets/images/caregiver-counseling-guide-understanding-pediatric-narcolepsy.jpg'
import imgESSNew from '../../../assets/images/ess-chad.jpg'
import imgDiaryNew from '../../../assets/images/sleep-cataplexy-diary.jpg'

import pdfClinicianGuide from '../../../assets/pdfs/hcp-narcolepsy-diagnosis-guide.pdf'
import pdfSymptomRecognition from '../../../assets/pdfs/hcp-narcolepsy-symptoms-recognition-brochure.pdf'
import pdfSymptomScreener from '../../../assets/pdfs/narcolepsy-symptom-screeners.pdf'
import pdfUnderstandingNarcolepsy from '../../../assets/pdfs/patient-understanding-narcolepsy-brochure.pdf'
import pdfClinicianGuidePed from '../../../assets/pdfs/hcp-narcolepsy-pediatrics-clinician-guide.pdf'
import pdfCaregiverPedGuide from '../../../assets/pdfs/caregiver-ped-guide.pdf'
import pdfESS from '../../../assets/pdfs/ess-chad.pdf'
import pdfDiary from '../../../assets/pdfs/sleep-cataplexy-diary.pdf'



let _resourceItems = [
  {
    title: 'Clinician Guide: Recognizing Narcolepsy',
    description:
      'Learn about recognizing and screening for excessive daytime sleepiness in narcolepsy, and the role of primary care physicians and other healthcare professionals in facilitating a diagnosis of this chronic disorder.',
    isAvailable: true,
    image: imgRecognizingNarcolepsy,
    alt: 'Clinician guide - recognizing narcolepsy image',
    pdf: pdfSymptomRecognition,
    viewTracking: 'resources, pdf view, view - clinician guide - recognizing narcolepsy',
    tracking: 'resources,	pdf download,	download print - clinician guide - recognizing narcolepsy'
  },
  {
    title: 'Clinician Guide for Sleep Specialists: Diagnosing Narcolepsy',
    description:
      'Developed specifically for sleep specialists, this reference includes a review of the symptoms of narcolepsy, guidance for conducting a clinical interview, and complete narcolepsy diagnostic criteria.',
    isAvailable: true,
    image: imgDiagnosingNarcolepsy,
    alt: 'Clinician guide for sleep specialists - diagnosing narcolepsy image',
    pdf: pdfClinicianGuide,
    viewTracking: 'resources, pdf view, view - clinician guide for sleep specialists - diagnosing narcolepsy',
    tracking: 'resources,	pdf download,	download print - clinician guide for sleep specialists - diagnosing narcolepsy'
  },
  {
    title: 'Narcolepsy Symptom Screeners',
    description:
      'The Narcolepsy Symptom Screeners include the Epworth Sleepiness Scale and Swiss Narcolepsy Scale, validated tools to screen patients who report manifestations of excessive daytime sleepiness and other symptoms associated with narcolepsy.',
    isAvailable: false,
    image: imgNarcolepsySymptom,
    alt: 'Narcolepsy symptom screener tearpad image',
    imgBig: true,
    pdf: pdfSymptomScreener,
    viewTracking: 'resources, pdf view, view - narcolepsy symptom screener',
    tracking: 'resources,	pdf download,	download print - narcolepsy symptom screener'
  },
  {
    title: 'Patient Guide: Understanding Narcolepsy',
    description: 'A guide to give to your patients to facilitate discussion about narcolepsy. ',
    isAvailable: false,
    image: imgPatientNarcolepsy,
    alt: 'Patient guide - understanding narcolespy image',
    pdf: pdfUnderstandingNarcolepsy,
    viewTracking: 'resources, pdf view, view - patient guide',
    tracking: 'resources,	pdf download, download print - patient guide'
  },
  {
    title: 'Clinician Guide: Narcolepsy in Pediatric Patients',
    description: 'Learn about recognizing all 5 main symptoms of narcolepsy and screening for excessive daytime sleepiness in pediatric patients.',
    isAvailable: false,
    image: imgClinicianGuideNew,
    alt: 'Clinician Guide: Narcolepsy in Pediatric Patients image',
    pdf: pdfClinicianGuidePed,
    viewTracking: 'resources, pdf view, view - clinical guide pediatric patients',
    tracking: 'resources,	pdf download,	download - clinical guide pediatric patients'
  },
  {
    title: 'Caregiver Counseling Guide: Understanding Pediatric Narcolepsy',
    description: 'A guide to give to caregivers of pediatric patients to facilitate discussion about narcolepsy.',
    isAvailable: false,
    image: imgCaregiverPedGuideNew,
    alt: 'Caregiver Counseling Guide: Understanding Pediatric Narcolepsy image',
    pdf: pdfCaregiverPedGuide,
    viewTracking: 'resources, pdf view, view - caregiver counseling guide',
    tracking: 'resources,	pdf download,	download - caregiver counseling guide'
  },
  {
    title: 'ESS-CHAD',
    description: 'Screen for excessive daytime sleepiness in pediatric patients.',
    isAvailable: false,
    imgBig: true,
    image: imgESSNew,
    alt: 'ESS-CHAD image',
    pdf: pdfESS,
    viewTracking: 'resources, pdf view, view - ess-chad',
    tracking: 'resources,	pdf download,	download - ess-chad'
  },
  {
    title: 'Sleep/Cataplexy Diary',
    description: 'A weekly sleep/cataplexy diary to give to patients and/or caregivers to help them track their sleep habits and cataplexy events, to facilitate recognition and diagnosis of narcolepsy.',
    isAvailable: false,
    imgBig: true,
    image: imgDiaryNew,
    alt: 'Sleep/Cataplexy Diary',
    pdf: pdfDiary,
    viewTracking: 'resources, pdf view, view - sleep/cataplexy diary',
    tracking: 'resources,	pdf download,	download - sleep cataplexy diary'
  },
  {
    visible: false,
    image: imgResourceKit,
    alt: 'Clinical resource kit - recognizing narcolepsy',
    title: 'Clinical Resource Kit: Recognizing Narcolepsy'
  },
  {
    visible: false,
    title: 'Clinical Resource Kit for Sleep Specialists: Diagnosing Narcolepsy',
    image: imgResourceKit,
    alt: 'Clinical resource kit - recognizing narcolepsy'
  }
]

export const resourceItems = _resourceItems